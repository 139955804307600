<script lang="ts">
	import branoproject from "@assets/images/brano-project.png?webp&h=540&imagetools";
	import sladkadilnaproject from "@assets/images/sladkadilna-project.png?webp&h=540&imagetools";
	import volvoproject from "@assets/images/volvo-project.png?webp&h=540&imagetools";
	import CarouselArrows from "@components/CarouselArrows.svelte";
	import Carousel from "@components/common/Carousel.svelte";
	import Formatted from "@components/common/Formatted.svelte";
	import Icon from "@components/common/Icon.svelte";
	import Media from "@components/common/Media.svelte";
	import type { EmblaCarouselType } from "embla-carousel";
	import add from "@core/assets/icons/add.svg?raw";
	import { getClientsPath } from "@core/schema/paths/getClientsPath.js";
	import { getGlobalTemplateProps } from "../contexts/getGlobalTemplateProps.js";
	import Pill from "./Pill.svelte";
	import { PillColor, PillSize } from "./PillProps.js";
	import Link from "./common/Link.svelte";
	import { ButtonVariant } from "./common/getButtonClass.js";

	const { tenant } = getGlobalTemplateProps();

	let carouselApi = $state<EmblaCarouselType>();

	interface Point {
		text: string;
		position: string;
	}

	interface Slide {
		name: string;
		image: string;
		labels: string[];
		points: Point[];
	}

	const slides: Slide[] = [
		{
			name: "Sladká dílna e-shop a CRM na míru",
			image: sladkadilnaproject,
			labels: ["E-shop", "CRM na míru", "Digitalizace & automatizace", "Tisk štítků", "B2C & B2B"],
			points: [
				{
					text: "wm[[45 dní]] od zadání  nl[[]] do spuštění wm[[MVP verze]]",
					position: "left-0  lg:-left-[12%] top-[40%]",
				},
				{
					text: "wm[[50% nárůst objednávek]] nl[[]] v prvním měsíci",
					position: "right-0 lg:-right-[14%] top-[10%]",
				},
				{
					text: "wm[[Eliminace personálních chyb]]  nl[[]] při vyřízení objednávky",
					position: "right-0 lg:-right-[7%] bottom-[7%]",
				},
			],
		},
		{
			name: "Brano Group Web",
			image: branoproject,
			labels: ["Webová prezentace", "Rychlost i s videi na webu", "Správa obsahu", "HR portál", "Správa dokumentů"],
			points: [
				{
					text: "wm[[Rychlost webu]] nl[[]] i při velkém digitálním obsahu",
					position: "right-0 lg:-right-[14%] top-[10%]",
				},
				{
					text: "wm[[Moderní prezentace]] nl[[]] korporátního webu",
					position: "left-0  lg:-left-[12%] top-[40%]",
				},
				{
					text: "wm[[HR portál]]  nl[[]] pro hladký náborní proces",
					position: "right-0 lg:-right-[7%] bottom-[7%]",
				},
			],
		},
		{
			name: "Volvo Cashdesk",
			image: volvoproject,
			labels: ["Napojení na interní servery", "Komunikace se SAP", "AWS serverless", "Angular", "ČSOB GAPA"],
			points: [
				{
					text: " Zjednodušenínl[[]] wm[[evidence účtenek]]",
					position: "right-0 lg:-right-[14%] top-[10%]",
				},
				{
					text: "Komunikace se skladem a  nl[[]] wm[[systémem SAP]]",
					position: "left-0  lg:-left-[12%] top-[40%]",
				},
				{
					text: "wm[[Integrace s platebními terminály]] nl[[]] přes ČSOB GAPA",
					position: "right-0 lg:-right-[7%] bottom-[7%]",
				},
			],
		},
	];
</script>

<section class="max-xl:full-width">
	<div class="flex flex-1 flex-col gap-4 justify-center">
		<Carousel options={{ loop: true }} bind:api={carouselApi} items={slides}>
			{#snippet item({ image, points, labels, name }: Slide)}
				<div class="flex flex-col gap-4 items-center basis-full">
					<div class="relative">
						<Media
							source={{ src: image, type: "image/webp" }}
							class="max-md:mb-4 w-full aspect-[768/465] object-contain"
							width={768}
							height={512}
							alt={name}
						/>
						<div class="grid-container md:contents">
							{#each points as point (point)}
								<div
									class="md:absolute bg-fstgreen w-fit rounded-[1.75rem] p-3 flex max-md:mb-2 max-md:mx-auto gap-3 pr-6 min-h-14 select-none {point.position}"
								>
									<Icon icon={add} class="justify-self-start h-8 p-1 bg-white text-fstgreen rounded-full" square />
									<div class="text-sm leading-[17px] my-auto text-black">
										<Formatted text={point.text}></Formatted>
									</div>
								</div>
							{/each}
						</div>
					</div>
					<div class="max-md:grid-container">
						<div class="flex gap-1 sm:gap-2 flex-wrap">
							{#each labels as label (label)}
								<Pill size={PillSize.Small} color={PillColor.White}>{label}</Pill>
							{/each}
						</div>
					</div>
				</div>
			{/snippet}
		</Carousel>
		<div class="max-md:order-first flex flex-col items-center md:mt-8">
			<div class="max-md:grid-container">
				<CarouselArrows api={carouselApi} slideCount={slides.length} />
			</div>
		</div>
		<div class="grid-container md:contents">
			<Link variant={ButtonVariant.GreenOutlineLightBg} href={getClientsPath(tenant)} class="mx-auto mt-2 sm:mt-4">
				<span class="inline-block md:hidden">Ukázat další projekty</span>
				<span class="hidden md:inline-block">Ukázat další úspěšné projekty</span>
			</Link>
		</div>
	</div>
</section>
